@use '../../assets/styles/partials/carousel';

.block-imageCarousel {
  grid-column: 1/-1;
  width: 100%;

  img {
    max-height: 80vh;
    max-height: clamp(27em, 80vh, 540px);
    max-width: 80vw;
    max-width:calc(100vw - var(--g-gap));
    width:auto;
  }

  li:not(.embla__slide) {
    display: inline-grid;
  }
}
